import React, { PureComponent } from "react";
import { Row, Col, Button, Input, Label, FormGroup } from "reactstrap";
import Messages from "../../Shared/Messages";
import classnames from "classnames";
import Text from "../../components/Text";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

function validateResetPasswordForm(data) {
  let errors = {};

  // let { inputType } = data;

  // if (inputType === "EMAIL") {
  //   let validEmail = data.email && validateEmail(data.email);
  //   if (!validEmail) {
  //     errors.email = true;
  //   }
  // } else if (inputType === "PHONE") {
  //   let validPhone =
  //     data.phone && validatePhoneNumber(data.countryCode + " " + data.phone);
  //   if (!validPhone) {
  //     errors.phone = true;
  //   }
  // }

  if (!data.password) {
    errors.password = true;
  }

  if (data.password !== data.rePassword) {
    errors.passwordNotSame = true;
  }
  return errors;
}

class ResetPasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputType: this.props.linkType
        ? this.props.linkType.toUpperCase()
        : "EMAIL",
      countryCode: "+353"
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState({ fieldErrors: {} });
    let fieldErrors = validateResetPasswordForm(this.state);
    if (Object.keys(fieldErrors).length >= 1) {
      this.setState({ fieldErrors });
      return;
    }

    let { password } = this.state;

    let data = { newPassword: password };
    // if (inputType === "EMAIL") {
    //   data.email = email;
    // } else if (inputType === "PHONE") {
    //   data.phoneNumber = doE164(countryCode, phone);
    // }

    this.props.resetPassword(data);
  };

  onUpdateInputType = value => {
    this.setState({ inputType: value });
  };

  onUpdateEmail = e => {
    this.setState({ email: e.target.value });
  };

  onUpdateCountryCode = cntrObj => {
    if (cntrObj) {
      this.setState({ countryCode: cntrObj.value });
    } else {
      this.setState({ countryCode: null });
    }
  };

  onUpdatePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onUpdatePassword = e => {
    this.setState({ password: e.target.value });
  };

  onUpdateRePassword = e => {
    this.setState({ rePassword: e.target.value });
  };

  toggleViewPassword = () => {
    this.setState({ viewPassword: !this.state.viewPassword });
  };

  toggleViewConfirmPassword = () => {
    this.setState({ viewConfirmPassword: !this.state.viewConfirmPassword });
  };

  render() {
    const { loading } = this.props;
    const disabled = loading;

    const { fieldErrors = {}, viewPassword, viewConfirmPassword } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <h4 className="mb-4">{intl.formatMessage(Messages.reset_password)}</h4>

        <FormGroup className="mb-3">
          <Label for="name">
            <Text content={Messages.password}/>
          </Label>
          <div className="inputHolder">
            <div className="input-group">
              <Input
                value={this.state.password}
                onChange={this.onUpdatePassword}
                type={viewPassword ? "text" : "password"}
                autoCapitalize="none"
                placeholder={intl.formatMessage(Messages.new_password)}
                disabled={disabled}
                className={classnames(
                  fieldErrors.password ? "is-invalid mb-3" : ""
                )}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text cursor-pointer"
                  onClick={this.toggleViewPassword}
                >
                  <i
                    className={`fa ${viewPassword ? "fa-eye-slash" : "fa-eye"}`}
                  />
                </span>
              </div>
            </div>
            <div className="invalid-feedback">Please set password.</div>
          </div>
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="name">
          <Text content={Messages.confirm_password}/>

          </Label>
          <div className="inputHolder">
            <div className="input-group">
              <Input
                value={this.state.rePassword}
                onChange={this.onUpdateRePassword}
                type={viewConfirmPassword ? "text" : "password"}
                autoCapitalize="none"
                placeholder={intl.formatMessage(Messages.reenter_new_pwd)}
                disabled={disabled}
                className={classnames(
                  fieldErrors.passwordNotSame ? "is-invalid mb-3" : ""
                )}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text cursor-pointer"
                  onClick={this.toggleViewConfirmPassword}
                >
                  <i
                    className={`fa ${
                      viewConfirmPassword ? "fa-eye-slash" : "fa-eye"
                    }`}
                  />
                </span>
              </div>
            </div>

            <div className="invalid-feedback">
              Password and re-entered password are not same.
            </div>
          </div>
        </FormGroup>

        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <Button color="primary" className="px-4" disabled={disabled}>
              {intl.formatMessage(Messages.submit)}
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default ResetPasswordForm;
