import moment from "moment";
import React, { Component } from "react";

class QuestionDate extends Component {
  render() {
    let { question, setData } = this.props;

    return (
      <input
        value={
          question.answers && question.answers[0]
            ? moment(question.answers[0].valueDate).format("YYYY-MM-DD")
            : ""
        }
        type="date"
        onChange={e => {
          setData({
            valueDate: e.target.value
          });
        }}
        defaultValue={question.initialValue}
        className={"form-control"}
        style={{ borderRadius: "4px" }}
      />
    );
  }
}

export default QuestionDate;
