import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import { enUS, fr, de } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-free/css/all.css";
import moment from "moment";
import I18nProvider from "../../../../services/I18nProvider";

const intl = I18nProvider;


registerLocale("en-US", enUS);
registerLocale("fr", fr);
registerLocale("de", de);

class DateObservation extends Component {
  static getDisplayValue(attributeValue, valuePlace, locale = enUS) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      let valueDate = null;
      if (valuePlace === "from") {
        valueDate = attributeValue.valueObservation.value.valueFromDate;
      } else if (valuePlace === "to") {
        valueDate = attributeValue.valueObservation.value.valueToDate;
      } else {
        valueDate = attributeValue.valueObservation.value.valueDate;
      }

      return valueDate
        ? moment(valueDate).format("YYYY-MM-DD")
        : "";
    }
  }

  static getPatientProfileDisplayValue(attributeValue, locale = fr) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      let valueDate = attributeValue.valueObservation.value.valueDate;

      if (valueDate) {
        return format(new Date(valueDate), "dd/MM/yyyy", { locale });
      } else {
        let valueString = attributeValue.valueObservation.value.valueString;
        if (valueString) {
          return format(new Date(valueString), "yyyy-MM-dd", { locale });
        }
      }
    }
  }

  static getValueForSubmission(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value &&
      attributeValue.valueObservation.value.valueDate
    ) {
      attributeValue.valueObservation.value.valueDate = new Date(
        attributeValue.valueObservation.value.valueDate
      ).getTime();
    }
    return attributeValue;
  }

  render() {
    let {
      attributeValue,
      setData,
      observationCode,
      locale = "fr", //TODO: pass locale from parent
    } = this.props;
    locale = intl.getLocale();
    locale = locale.slice(0, 2);

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueDate
      ? new Date(attributeValue.valueObservation.value.valueDate)
      : null;
    attributeValue.valueObservation.code = observationCode;

    return (
      <div className="inputHolder flex-row">
        <div
          className="date-picker-wrapper"
          style={{ position: "relative", display: "inline-block" }}
        >
          <DatePicker
            className="ui-attribute-el"
            selected={obsVal}
            onChange={(date) => {
              const formattedDate = format(date, "yyyy-MM-dd");
              attributeValue.valueObservation.value.valueDate = formattedDate;
              attributeValue.valueObservation.value.valueString = null;
              attributeValue.valueObservation.value.valueAsString = null;
              setData(attributeValue, formattedDate);
            }}
            onBlur={this.props.onBlur}
            dateFormat="dd/MM/yyyy"
            locale={locale}
            placeholderText="dd/mm/yyyy"
          />
          <span
            className="fa fa-calendar"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              color: "#aaa",
            }}
          />
        </div>
      </div>
    );
  }
}

export default DateObservation;
